<template>
  <Game />
</template>

<script>
import Game from '@/components/Game.vue'

export default {
  components: {
    Game,
  },
}
</script>
