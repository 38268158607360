<template>
  <StartGameForm />
</template>

<script>
import StartGameForm from '@/components/StartGameForm.vue'
export default {
  name: 'Home',
  components: { StartGameForm },
}
</script>
