import axios from 'axios'
import { mapState } from 'vuex'

const apiClient = axios.create({
  // baseURL: 'http://localhost:3000',
  baseURL: 'https://opentdb.com',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export default {
  computed: {
    ...mapState(['token']),
  },
  getToken() {
    if (this.token) {
      return new Promise((resolve) => {
        resolve({ response_code: 0, token: this.token })
      })
    }
    return this.generateToken()
  },
  generateToken() {
    return apiClient.get('/api_token.php?command=request')
  },
  resetToken(token) {
    return apiClient.get('api_token.php?command=reset&token=' + token)
  },
  getCategories() {
    return apiClient.get('/api_category.php')
  },
  getDifficulties() {
    return ['easy', 'medium', 'hard']
  },
  generateQuizz(token, category, difficulty) {
    return apiClient.get(
      '/api.php?amount=10&token=' +
        token +
        '&category=' +
        category +
        '&difficulty=' +
        difficulty
    )
  },
}
