<template>
  <div v-if="game">
    <h3>Your final score: {{ game.score }}/{{ game.questions.length }}</h3>
    <ul>
      <li v-for="(question, index) in game.questions" :key="question">
        <span :class="{ success: isSuccess(index), fail: !isSuccess(index) }">
          <span v-html="question.question"></span>&nbsp;
          <span v-html="getUserAnswer(index)"></span>
          <span v-if="!isSuccess(index)">
            : The good answer was "<span v-html="getGoodAnswer(question)"></span
            >"
          </span>
        </span>
      </li>
    </ul>
    <router-link :to="{ name: 'Home' }">
      <button>Start new game</button>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      //
    }
  },
  computed: {
    ...mapState(['game']),
  },
  methods: {
    isSuccess(index) {
      return (
        this.game.answers[index] === this.game.questions[index].correctAnswer
      )
    },
    getUserAnswer(index) {
      return this.game.questions[index].answers[this.game.answers[index]]
    },
    getGoodAnswer(question) {
      return question.answers[question.correctAnswer]
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.success {
  color: green;
}
.fail {
  color: red;
}
</style>
