<template>
  <div>
    <form @submit.prevent="onSubmit">
      <h1 data-testid="new-game-title">New Game</h1>

      <div v-if="errors.length">
        <b>Please correct the following error(s):</b>
        <ul>
          <li v-for="error in errors" :key="error" class="error">
            {{ error }}
          </li>
        </ul>
      </div>

      <h4>Category</h4>
      <label>Select a category: </label>
      <select v-model="game.category">
        <option
          v-for="category in categories"
          :key="category.id"
          :value="category.id"
        >
          {{ category.name }}
        </option>
      </select>

      <h4>Difficulty</h4>

      <label v-for="difficulty in difficulties" :key="difficulty">
        <input
          v-model="game.difficulty"
          type="radio"
          :id="difficulty"
          name="difficulty"
          class="radio"
          :value="difficulty"
        />
        {{ difficulty }}
        <br />
      </label>
      <br /><br />
      <button type="submit">Start new game</button>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'StartGameForm',
  data() {
    return {
      game: {
        category: null,
        difficulty: null,
      },
      errors: [],
    }
  },
  created() {
    this.fetchCategories()
    this.fetchDifficulties()
  },
  computed: {
    ...mapState(['categories', 'difficulties']),
  },
  methods: {
    ...mapActions(['fetchCategories', 'fetchDifficulties', 'createGame']),
    onSubmit(e) {
      if (this.game.category && this.game.difficulty) {
        this.createGame(this.game)
          .then(() => {
            this.$router.push({
              name: 'Game',
            })
          })
          .catch((error) => {
            console.log('error', error)
          })
      }
      this.errors = []

      if (!this.game.category) {
        this.errors.push('Category required.')
      }
      if (!this.game.difficulty) {
        this.errors.push('Difficulty required.')
      }

      e.preventDefault()
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.error {
  color: red;
}
</style>
