import { createStore } from 'vuex'
import OpentriviadbService from '@/services/OpentriviadbService.js'

export default createStore({
  state: {
    categories: [],
    difficulties: [],
    game: null,
    opentdbToken: null,
  },
  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
    SET_DIFFICULTIES(state, difficulties) {
      state.difficulties = difficulties
    },
    CREATE_GAME(state, game) {
      state.game = game
    },
    UPDATE_SCORE(state, score) {
      state.game.score = score
    },
  },
  actions: {
    fetchCategories({ commit }) {
      return OpentriviadbService.getCategories()
        .then((response) => {
          if (response.data.trivia_categories) {
            commit('SET_CATEGORIES', response.data.trivia_categories)
          }
        })
        .catch((error) => {
          throw error
        })
    },
    fetchDifficulties({ commit }) {
      const difficulties = OpentriviadbService.getDifficulties()
      commit('SET_DIFFICULTIES', difficulties)
    },
    createGame({ commit }, game) {
      OpentriviadbService.getToken().then((response) => {
        if (response.data.response_code === 0) {
          const token = response.data.token
          return OpentriviadbService.generateQuizz(
            token,
            game.category,
            game.difficulty
          )
            .then((response) => {
              if (response.data.response_code === 0) {
                let questions = []
                response.data.results.forEach(function (element) {
                  //gather all answers (right one + wrong ones)
                  let allAnswers = element.incorrect_answers
                  allAnswers.push(element.correct_answer)
                  //Shuffle them using the Fisher-Yates algorithm
                  const shuffleArray = (array) => {
                    for (let i = array.length - 1; i > 0; i--) {
                      const j = Math.floor(Math.random() * (i + 1))
                      const temp = array[i]
                      array[i] = array[j]
                      array[j] = temp
                    }
                  }
                  shuffleArray(allAnswers)
                  //find the right answer's index
                  const correctAnswerIndex = allAnswers.indexOf(
                    element.correct_answer
                  )
                  //build the complete step of question + shuffle answers + correct answer's position
                  const question = {
                    question: element.question,
                    answers: allAnswers,
                    correctAnswer: correctAnswerIndex,
                  }
                  //push it
                  questions.push(question)
                })
                const game = {
                  ...game,
                  questions: questions,
                  answers: [],
                  score: 0,
                }
                return commit('CREATE_GAME', game)
              }
              throw "couldn't generate a quizz"
            })
            .catch((error) => {
              throw error
            })
        } else {
          throw "couldn't generate a token"
        }
      })
    },
    finishGame({ commit }, game) {
      let score = 0
      for (let i = 0; i < game.questions.length; i++) {
        if (game.answers[i] === game.questions[i].correctAnswer) {
          score++
        }
      }
      commit('UPDATE_SCORE', score)
    },
  },
  modules: {},
})
