<template>
  <div v-if="game">
    <Question
      :question="game.questions[currentIndex]"
      :currentIndex="currentIndex"
      :totalQuestions="totalQuestions"
      :currentSelection="currentSelection"
      @selectAnswer="selectAnswer"
    />
    <button
      @click="previous()"
      :disabled="currentIndex === 0"
      :class="{ disabledButton: currentIndex === 0 }"
    >
      Previous question
    </button>
    <button
      @click="next()"
      :disabled="
        currentSelection === null || currentIndex === totalQuestions - 1
      "
      :class="{
        disabledButton:
          currentSelection === null || currentIndex === totalQuestions - 1,
      }"
    >
      Next question
    </button>
    <br /><br />
    <button
      v-show="currentIndex === totalQuestions - 1"
      :disabled="currentSelection === null"
      :class="{ disabledButton: currentSelection === null }"
      @click="endGame()"
    >
      Submit my answers
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Question from '@/components/Question.vue'

export default {
  components: {
    Question,
  },
  data() {
    return {
      currentIndex: 0,
      currentSelection: null,
    }
  },
  created() {
    this.currentIndex = 0
    this.currentSelection = null
  },
  computed: {
    ...mapState(['game']),
    totalQuestions() {
      return this.game.questions.length
    },
  },
  methods: {
    ...mapActions(['finishGame']),
    previous() {
      if (this.currentIndex > 0) {
        this.currentIndex--
        this.currentSelection = this.game.answers[this.currentIndex]
      }
    },
    next() {
      if (this.currentIndex < this.game.questions.length - 1) {
        this.currentIndex++
        if (this.game.answers[this.currentIndex] !== undefined) {
          this.currentSelection = this.game.answers[this.currentIndex]
        } else {
          this.currentSelection = null
        }
      }
    },
    selectAnswer(currentIndex, currentSelection) {
      this.currentSelection = currentSelection
      this.game.answers[currentIndex] = currentSelection
    },
    endGame() {
      this.finishGame(this.game)
        .then(() => {
          this.$router.push({
            name: 'EndGame',
          })
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.disabledButton {
  background: #d8d8d8;
  cursor: not-allowed;
}
</style>
