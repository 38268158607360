<template>
  <div>
    <h1>Question {{ currentIndex + 1 }} / {{ totalQuestions }}</h1>
    <span v-html="question.question"></span>
    <br /><br /><br />
    <div>
      <label v-for="(answer, index) in question.answers" :key="answer">
        <input
          v-model="selected"
          type="radio"
          :name="name"
          :value="index"
          :checked="index === currentSelection"
          @change="selectAnswer()"
        />
        <span v-html="answer"></span>
        <br />
      </label>
    </div>
    <br /><br /><br />
  </div>
</template>

<script>
export default {
  name: 'Question',
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    name() {
      return 'question_' + this.currentIndex.toString()
    },
  },
  emits: ['selectAnswer'],
  methods: {
    selectAnswer() {
      this.$emit('selectAnswer', this.currentIndex, this.selected)
    },
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    totalQuestions: {
      type: Number,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
    currentSelection: {
      type: Number,
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
